import { combineReducers } from "redux";
import userReducer from "./user/reducer";
import profilesReducer from "./profiles/reducer";
import messagesReducer from "./messages/reducer";

const rootReducer = combineReducers({
    user: userReducer,
    profiles: profilesReducer,
    messages: messagesReducer,
});

export default rootReducer;
