import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from "./types";

const initialState = {
    user: null,
    username: "",
    loading: false,
    error: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_REQUEST:
            return {
                ...state,
                loadding: true,
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                username: action.payload.username,
                loadding: false,
            };
        case FETCH_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadding: false,
            };
        default:
            return state;
    }
};

export default userReducer;
