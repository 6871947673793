import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from "./types";
import { Auth } from "aws-amplify";

export const fetchUser = () => {
    return (dispatch) => {
        dispatch(fetchUserRequest());
        Auth.currentAuthenticatedUser()
            .then((user) => dispatch(fetchUserSuccess(user)))
            .catch((error) => dispatch(fetchUserFailure(error)));
    };
};

export const fetchUserRequest = () => {
    return {
        type: FETCH_USER_REQUEST,
    };
};

export const fetchUserSuccess = (user) => {
    return {
        type: FETCH_USER_SUCCESS,
        payload: user,
    };
};

export const fetchUserFailure = (error) => {
    return {
        type: FETCH_USER_FAILURE,
        payload: error,
    };
};
