/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      name
      year
      month
      day
      lunar
      leapMonth
      timezone
      locale
      language
      sendmail
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      name
      year
      month
      day
      lunar
      leapMonth
      timezone
      locale
      language
      sendmail
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      name
      year
      month
      day
      lunar
      leapMonth
      timezone
      locale
      language
      sendmail
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      writer
      message
      createdAt
      updatedAt
      owner
      recommends {
        items {
          id
          messageID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      writer
      message
      createdAt
      updatedAt
      owner
      recommends {
        items {
          id
          messageID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      writer
      message
      createdAt
      updatedAt
      owner
      recommends {
        items {
          id
          messageID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createRecommend = /* GraphQL */ `
  mutation CreateRecommend(
    $input: CreateRecommendInput!
    $condition: ModelRecommendConditionInput
  ) {
    createRecommend(input: $input, condition: $condition) {
      id
      messageID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRecommend = /* GraphQL */ `
  mutation UpdateRecommend(
    $input: UpdateRecommendInput!
    $condition: ModelRecommendConditionInput
  ) {
    updateRecommend(input: $input, condition: $condition) {
      id
      messageID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRecommend = /* GraphQL */ `
  mutation DeleteRecommend(
    $input: DeleteRecommendInput!
    $condition: ModelRecommendConditionInput
  ) {
    deleteRecommend(input: $input, condition: $condition) {
      id
      messageID
      createdAt
      updatedAt
      owner
    }
  }
`;
