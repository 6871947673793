import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "aws-amplify";
import { Form, Button } from "react-bootstrap";
import { createMessage } from "graphql/mutations";

const MessageAdd = ({ username }) => {
    // translation
    const { t } = useTranslation();
    const [message, setMessage] = useState("");

    async function addMessage(e) {
        console.log("MessageAdd.addMessage");
        e.preventDefault();
        if (!message) return;
        if (!username) return;

        await API.graphql({
            query: createMessage,
            variables: {
                input: {
                    message: message,
                    writer: username,
                },
            },
        });
        setMessage("");
    }

    return (
        <div>
            <Form className="form">
                <Form.Control
                    type="text"
                    key="message"
                    className="my-1 mr-sm-2 name"
                    placeholder={t("home.message.placeholder")}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                    variant="primary"
                    className="my-1"
                    size="sm"
                    type="submit"
                    key="submit"
                    onClick={addMessage}
                >
                    {t("home.submit")}
                </Button>
            </Form>
        </div>
    );
};

export default MessageAdd;
