import { FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILURE } from "./types";
import { API } from "aws-amplify";
import { listMessages } from "graphql/queries";
import { onCreateMessage, onUpdateMessage, onDeleteMessage } from "graphql/subscriptions";

const moment = require("moment");

export const subscribeMessage = () => {
    return (dispatch) => {
        API.graphql({ query: onCreateMessage }).subscribe({
            next: () => {
                console.log("onCreateMessage");
                dispatch(fetchMessages());
            },
        });
        API.graphql({ query: onUpdateMessage }).subscribe({
            next: () => {
                console.log("onUpdateMessage");
                dispatch(fetchMessages());
            },
        });
        API.graphql({ query: onDeleteMessage }).subscribe({
            next: () => {
                console.log("onDeleteMessage");
                dispatch(fetchMessages());
            },
        });
    };
};

export const fetchMessages = () => {
    return (dispatch) => {
        const filter = {
            or: {
                and: {
                    month: { eq: moment().month() + 1 },
                    day: { eq: moment().get("date") },
                    lunar: { eq: false },
                },
                and: {
                    month: { eq: 6 },
                    day: { eq: 20 },
                    lunar: { eq: true },
                },
            },
        };
        // console.log(filter);

        dispatch(fetchMessagesRequest());
        API.graphql({
            query: listMessages,
            // variables: { filter: filter },
            authMode: "API_KEY",
        })
            .then(
                ({
                    data: {
                        listMessages: { items },
                    },
                }) => dispatch(fetchMessagesSuccess(items))
            )
            .catch((error) => dispatch(fetchMessagesFailure(error)));
    };
};

export const fetchMessagesRequest = () => {
    return {
        type: FETCH_MESSAGES_REQUEST,
    };
};

export const fetchMessagesSuccess = (items) => {
    return {
        type: FETCH_MESSAGES_SUCCESS,
        payload: items,
    };
};

export const fetchMessagesFailure = (error) => {
    return {
        type: FETCH_MESSAGES_FAILURE,
        payload: error,
    };
};
