import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { MessageAdd, MessageItem, BirthdayItem } from "components/home";

const Home = ({ username, messages, profiles }) => {
    // translation
    const { t } = useTranslation();

    // const [profiles, setProfiles] = useState([]);
    // const [messages, setMessages] = useState([]);

    // useEffect(() => {
    //     // fetchDate();
    //     fetchProfiles();
    //     fetchMessages();
    // }, []);

    // async function fetchDate() {
    //     console.log("Home => fetchDate");
    //     let bOk = calendar.toLunar("2021", "06", "20", function (json) {
    //         if (bOk) {
    //             console.log(json);
    //         }
    //     });
    //     console.log("after calendar.toLunar");
    // }

    // async function fetchProfiles() {
    //     console.log("Home => fetchProfiles");

    //     // get profiles
    //     const filter = {
    //         or: {
    //             and: {
    //                 month: { eq: moment().month() + 1 },
    //                 day: { eq: moment().get("date") },
    //                 lunar: { eq: false },
    //             },
    //             and: {
    //                 month: { eq: 6 },
    //                 day: { eq: 20 },
    //                 lunar: { eq: true },
    //             },
    //         },
    //     };
    //     // console.log(filter);

    //     API.graphql({
    //         query: listProfiles,
    //         // variables: { filter: filter },
    //         authMode: "API_KEY",
    //     })
    //         .then(
    //             ({
    //                 data: {
    //                     listProfiles: { items },
    //                 },
    //             }) => {
    //                 console.log("Home => fetchProfiles", items);
    //                 if (items?.length > 0) {
    //                     setProfiles(items);
    //                 }
    //             }
    //         )
    //         .catch((err) => {
    //             console.log("Home => fetchProfiles", err);
    //         });
    // }

    // async function fetchMessages() {
    //     console.log("Home => fetchMessages");
    //     const filter = {
    //         or: {
    //             and: {
    //                 month: { eq: moment().month() + 1 },
    //                 day: { eq: moment().get("date") },
    //                 lunar: { eq: false },
    //             },
    //             and: {
    //                 month: { eq: 6 },
    //                 day: { eq: 20 },
    //                 lunar: { eq: true },
    //             },
    //         },
    //     };
    //     // console.log(filter);

    //     API.graphql({
    //         query: listMessages,
    //         // variables: { filter: filter },
    //         authMode: "API_KEY",
    //     })
    //         .then(
    //             ({
    //                 data: {
    //                     listMessages: { items },
    //                 },
    //             }) => {
    //                 console.log("Home => fetchMessages", items);
    //                 if (items?.length > 0) {
    //                     setMessages(items);
    //                 }
    //             }
    //         )
    //         .catch((err) => {
    //             console.log("Home => fetchMessages", err);
    //         });
    // }

    // API.graphql({ query: onCreateMessage }).subscribe({
    //     next: () => {
    //         console.log("onCreateMessage");
    //         fetchMessages();
    //     },
    // });
    // API.graphql({ query: onUpdateMessage }).subscribe({
    //     next: () => {
    //         console.log("onUpdateMessage");
    //         fetchMessages();
    //     },
    // });
    // API.graphql({ query: onDeleteMessage }).subscribe({
    //     next: () => {
    //         console.log("onDeleteMessage");
    //         fetchMessages();
    //     },
    // });

    return (
        <div className="m-5">
            {profiles && (
                <div>
                    <h4>{t("home.title.todaybirthday")}</h4>
                    {profiles.map((profile, index) => (
                        <BirthdayItem key={index} {...profile} username={username} />
                    ))}
                </div>
            )}

            {username && (
                <div>
                    <hr />
                    <h4>{t("home.title.addmessage")}</h4>
                    <MessageAdd username={username} />
                </div>
            )}

            {messages && (
                <div>
                    <hr />
                    <h4>{t("home.title.listmessage")}</h4>
                    {messages.map((msg, index) => (
                        <MessageItem key={index} {...msg} username={username} />
                    ))}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({ user, profiles, messages }) => {
    return {
        username: user.username,
        messages: messages.items,
        profiles: profiles.items,
    };
};

export default connect(mapStateToProps)(Home);
