import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
    // translation
    const { t, i18n } = useTranslation();

    return (
        <div className="m-5">
            <h2>{t("about.title")}</h2>
        </div>
    );
};

export default About;
