import { FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILURE } from "./types";

const initialState = {
    items: [],
    loading: false,
    error: null,
};

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MESSAGES_REQUEST:
            return {
                ...state,
                loadding: true,
            };
        case FETCH_MESSAGES_SUCCESS:
            return {
                ...state,
                items: action.payload,
                loadding: false,
            };
        case FETCH_MESSAGES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadding: false,
            };
        default:
            return state;
    }
};

export default messagesReducer;
