import React from "react";
import { useTranslation } from "react-i18next";

const BirthdayItem = (props) => {
    // translation
    const { t } = useTranslation();

    return <span className="span_name">&nbsp;{props.name}&nbsp;</span>;
};

export default BirthdayItem;
