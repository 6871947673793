import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Form, Button } from "react-bootstrap";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
//import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import { listMessages } from "graphql/queries";
// import {
//   onCreateProfile,
//   onUpdateProfile,
//   onDeleteProfile,
// } from "graphql/subscriptions";
import { ProfileAdd } from "components/mypage";
import { MessageItem } from "components/home";

const moment = require("moment");

const Mypage = ({ username }) => {
    console.log("Mypage => username");
    // translation
    const { t } = useTranslation();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        fetchMessages();
    }, [username]);

    async function fetchMessages() {
        console.log("Home => fetchMessages");
        const filter = {
            and: {
                writer: { eq: username },
            },
        };
        // console.log(filter);

        API.graphql({
            query: listMessages,
            variables: { filter: filter },
            // authMode: "API_KEY",
        })
            .then(
                ({
                    data: {
                        listMessages: { items },
                    },
                }) => {
                    console.log("Home => fetchMessages", items);
                    if (items?.length > 0) {
                        setMessages(items);
                    }
                }
            )
            .catch((err) => {
                console.log("Home => fetchMessages", err);
            });
    }

    // API.graphql(
    //   graphqlOperation(onCreateProfile, {
    //     owner: username,
    //   })
    // ).subscribe({
    //   next: ({ provider, pf }) => {
    //     console.log("onCreateProfile");
    //     console.log(pf);
    //     fetchProfile();
    //   },
    // });
    // API.graphql(graphqlOperation(onUpdateProfile)).subscribe({
    //   next: ({ provider, profile }) => {
    //     console.log("onUpdateProfile");
    //     fetchProfile();
    //   },
    // });
    // API.graphql(graphqlOperation(onDeleteProfile)).subscribe({
    //   next: ({ provider, profile }) => {
    //     console.log("onDeleteProfile");
    //     fetchProfile();
    //   },
    // });

    return (
        <div className="m-5">
            <hr />
            <h4>{t("mypage.title")}</h4>
            <ProfileAdd username={username} />

            <hr />
            <h4>{t("mypage.title.mymessage")}</h4>
            {messages.map((msg, index) => (
                <MessageItem key={index} {...msg} username={username} />
            ))}

            <hr />
            <h4>{t("mypage.title.mylike")}</h4>
        </div>
    );
};

export default withAuthenticator(Mypage, {
    includeGreetings: true,
});
