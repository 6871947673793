import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { updateMessage, deleteMessage } from "graphql/mutations";
import { API } from "aws-amplify";

const MessageItem = (props) => {
    // translation
    const { t } = useTranslation();
    const [isModify, setModify] = useState(false);
    const [message, setMessage] = useState(props.message);

    // 좋아요.
    async function fnLike() {
        console.log("fnLike");
        alert("Comming soon...");
    }
    // 댓글.
    async function fnReply() {
        console.log("fnReply");
        alert("Comming soon...");
    }
    // 수정.
    async function fnModify() {
        console.log("fnModify");
        setModify(true);
    }
    // 수정 취소.
    async function fnCancelModify() {
        console.log("fnCancelModify");
        setModify(false);
        setMessage(props.message);
    }
    // delete message
    async function fnDeleteMessage({ id }) {
        console.log("fnDeleteMessage", id);
        if (window.confirm("삭제 하시겠습니까?")) {
            await API.graphql({
                query: deleteMessage,
                variables: { input: { id } },
            });
        }
    }

    async function hnMessage(e) {
        console.log("MessageItem.hnMessage");
        e.preventDefault();
        if (!message) return;
        if (!props.username) return;

        await API.graphql({
            query: updateMessage,
            variables: {
                input: {
                    id: props.id,
                    message: message,
                },
            },
        });
        setModify(false);
    }

    return (
        <div>
            <p>
                {!isModify && message}
                {isModify && (
                    <Form className="form">
                        <Form.Control
                            type="text"
                            key="message"
                            className="my-1 mr-sm-2 name"
                            placeholder={t("home.message.placeholder")}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <Button
                            variant="primary"
                            className="my-1"
                            size="sm"
                            type="submit"
                            key="submit"
                            onClick={hnMessage}
                        >
                            {t("home.update")}
                        </Button>
                        <Button
                            variant="secondary"
                            className="my-1"
                            size="sm"
                            type="button"
                            key="cancel"
                            onClick={fnCancelModify}
                        >
                            {t("home.cancel")}
                        </Button>
                    </Form>
                )}
                <br />
                {props.owner !== props.username && (
                    <a
                        className="caMessageCommand"
                        href="#"
                        onClick={() => {
                            fnLike();
                            return false;
                        }}
                    >
                        {t("message.btn.like")}
                    </a>
                )}
                {props.owner !== props.username && (
                    <a
                        className="caMessageCommand"
                        href="#"
                        onClick={() => {
                            fnReply();
                            return false;
                        }}
                    >
                        {t("message.btn.reply")}
                    </a>
                )}
                {props.owner === props.username && (
                    <a
                        className="caMessageCommand"
                        href="#"
                        onClick={() => {
                            fnModify();
                            return false;
                        }}
                    >
                        {t("message.btn.edit")}
                    </a>
                )}
                {props.owner === props.username && (
                    <a
                        className="caMessageCommand"
                        href="#"
                        onClick={() => {
                            fnDeleteMessage({ ...props });
                            return false;
                        }}
                    >
                        {t("message.btn.delete")}
                    </a>
                )}
            </p>
        </div>
    );
};

export default MessageItem;
