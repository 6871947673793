import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    fetchUser,
    fetchProfiles,
    fetchMessages,
    subscribeProfile,
    subscribeMessage,
} from "../redux";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { Home, Mypage, Privacy, Terms, About } from "pages";
import { Menu, Footer } from "components";
import { Hub } from "aws-amplify";

const App = ({
    username,
    fetchUser,
    fetchProfiles,
    fetchMessages,
    subscribeProfile,
    subscribeMessage,
}) => {
    // translation
    const { t } = useTranslation();

    const listener = (data) => {
        switch (data.payload.event) {
            case "signIn":
                console.log("Hub.listener => user signed in");
                fetchUser();
                break;
            case "signUp":
                console.log("Hub.listener => user signed up");
                break;
            case "signOut":
                console.log("Hub.listener => user signed out");
                break;
            case "signIn_failure":
                console.log("Hub.listener => user sign in failed");
                break;
            case "tokenRefresh":
                console.log("Hub.listener => token refresh succeeded");
                break;
            case "tokenRefresh_failure":
                console.log("Hub.listener => token refresh failed");
                break;
            case "configured":
                console.log("Hub.listener => the Auth module is configured");
        }
    };

    useEffect(() => {
        document.title = t("title");
        fetchUser();
        subscribeMessage();
        fetchMessages();
        subscribeProfile();
        fetchProfiles();
        Hub.listen("auth", listener);
    }, []);

    return (
        <div className="container py-3">
            <Menu username={username} />
            <Route exact path="/" component={Home} />
            <Route path="/mypage" render={() => <Mypage username={username} />} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/about" component={About} />
            <Footer />
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    return {
        username: user.username,
    };
};

const mapDispatchToProps = {
    fetchUser,
    fetchProfiles,
    fetchMessages,
    subscribeProfile,
    subscribeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
