import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API } from "aws-amplify";
import { Form, Button } from "react-bootstrap";
import { getProfile } from "graphql/queries";
import {
    createProfile as createProfileMutation,
    updateProfile as updateProfileMutation,
} from "graphql/mutations";

const initialFormState = {
    name: "",
    year: 2021,
    month: 1,
    day: 1,
    lunar: false,
    leapMonth: false,
    timezone: "",
    locale: "",
    language: "",
    sendmail: true,
};
// month (1 ~ 12)
const monthValues = Array(12).fill(0);
// date (1 ~ 31)
const dateValues = new Array(31).fill(0);

const ProfileAdd = ({ username }) => {
    // translation
    const { t } = useTranslation();
    const [profile, setProfile] = useState(initialFormState);
    const [isDoing, setDoing] = useState(false);

    useEffect(() => {
        fetchProfile();
    }, [username]);

    async function fetchProfile() {
        console.log("Mypage => fetchProfile", username);
        if (username) {
            API.graphql({
                query: getProfile,
                variables: { id: username },
            })
                .then(({ data: { getProfile } }) => {
                    console.log("Mypage => fetchProfile", getProfile);
                    if (getProfile) {
                        setProfile(getProfile);
                    }
                })
                .catch((err) => {
                    console.log("Mypage => fetchProfile : err", err);
                });
        }
    }

    async function updateProfile(e) {
        setDoing(true);
        console.log("Mypage => updateProfile");
        e.preventDefault();
        if (profile.name) {
            if (profile.id) {
                console.log("Mypage => updateProfile - update");
                API.graphql({
                    query: updateProfileMutation,
                    variables: {
                        input: {
                            id: profile.id,
                            name: profile.name,
                            month: profile.month,
                            day: profile.day,
                            lunar: !!profile.lunar,
                            leapMonth: !!profile.leapMonth,
                        },
                    },
                })
                    .then((data) => {
                        console.log("Mypage => updateProfile - create", data);
                        alert(t("mypage.msg.success"));
                    })
                    .catch((err) => {
                        console.log("Mypage => updateProfile - create", err);
                        alert(t("mypage.msg.failed"));
                    });
            } else {
                console.log("Mypage => updateProfile - create");
                API.graphql({
                    query: createProfileMutation,
                    variables: {
                        input: {
                            id: username,
                            name: profile.name,
                            month: profile.month,
                            day: profile.day,
                            lunar: !!profile.lunar,
                            leapMonth: !!profile.leapMonth,
                        },
                    },
                })
                    .then((data) => {
                        console.log("Mypage => updateProfile - create", data);
                        alert(t("mypage.msg.success"));
                    })
                    .catch((err) => {
                        console.log("Mypage => updateProfile - create", err);
                        alert(t("mypage.msg.failed"));
                    });
            }
        } else {
            alert(t("mypage.msg.entername"));
        }
        setDoing(false);
    }

    return (
        <Form className="form">
            <Form.Control
                type="text"
                key="name"
                className="my-1 mr-sm-2 name"
                placeholder={t("mypage.name.placeholder")}
                value={profile.name}
                onChange={(e) => setProfile({ ...profile, name: e.target.value })}
            />
            <Form.Control
                as="select"
                key="month"
                className="my-1 mr-sm-2"
                placeholder={t("mypage.month.placeholder")}
                value={profile.month}
                onChange={(e) => setProfile({ ...profile, month: e.target.value })}
            >
                {monthValues.map((v, index) => (
                    <option key={index}>{index + 1}</option>
                ))}
            </Form.Control>
            <Form.Control
                as="select"
                key="day"
                className="my-1 mr-sm-2"
                placeholder={t("mypage.day.placeholder")}
                value={profile.day}
                onChange={(e) => setProfile({ ...profile, day: e.target.value })}
            >
                {dateValues.map((v, index) => (
                    <option key={index}>{index + 1}</option>
                ))}
            </Form.Control>
            <Form.Check
                label={t("mypage.lunar")}
                className="my-1 mr-sm-2 checkbox"
                type="checkbox"
                key="lunar"
                checked={profile.lunar}
                onChange={(e) => setProfile({ ...profile, lunar: e.target.checked ? 1 : 0 })}
            />
            <Form.Check
                label={t("mypage.leapMonth")}
                className="my-1 mr-sm-2 checkbox"
                type="checkbox"
                key="leapMonth"
                checked={profile.leapMonth}
                onChange={(e) =>
                    setProfile({
                        ...profile,
                        leapMonth: e.target.checked ? 1 : 0,
                    })
                }
            />
            <Button
                variant="primary"
                className="my-1"
                size="sm"
                type="submit"
                key="submit"
                disabled={isDoing}
                onClick={updateProfile}
            >
                {t("mypage.submit")}
            </Button>
        </Form>
    );
};

export default ProfileAdd;
