import './i18n';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Root from 'client/Root';
import 'index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import Amplify from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <Root />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();