/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateProfile = /* GraphQL */ `
  subscription OnCreateProfile {
    onCreateProfile {
      id
      name
      year
      month
      day
      lunar
      leapMonth
      timezone
      locale
      language
      sendmail
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateProfile = /* GraphQL */ `
  subscription OnUpdateProfile {
    onUpdateProfile {
      id
      name
      year
      month
      day
      lunar
      leapMonth
      timezone
      locale
      language
      sendmail
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteProfile = /* GraphQL */ `
  subscription OnDeleteProfile {
    onDeleteProfile {
      id
      name
      year
      month
      day
      lunar
      leapMonth
      timezone
      locale
      language
      sendmail
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      writer
      message
      createdAt
      updatedAt
      owner
      recommends {
        items {
          id
          messageID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      writer
      message
      createdAt
      updatedAt
      owner
      recommends {
        items {
          id
          messageID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      writer
      message
      createdAt
      updatedAt
      owner
      recommends {
        items {
          id
          messageID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateRecommend = /* GraphQL */ `
  subscription OnCreateRecommend {
    onCreateRecommend {
      id
      messageID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateRecommend = /* GraphQL */ `
  subscription OnUpdateRecommend {
    onUpdateRecommend {
      id
      messageID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteRecommend = /* GraphQL */ `
  subscription OnDeleteRecommend {
    onDeleteRecommend {
      id
      messageID
      createdAt
      updatedAt
      owner
    }
  }
`;
