/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:8f13d7fd-a698-4500-956f-9e3a362911e0",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_VCe4Uzgjt",
    "aws_user_pools_web_client_id": "47puepgpl0t47uj13294ggmc8e",
    "oauth": {
        "domain": "urbirthday03-dev-dev.auth.ap-northeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev.urbirthday.com/",
        "redirectSignOut": "https://dev.urbirthday.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://6ltlcbk2e5h75jroouxyhzq7ay.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6rzmptllt5c4jdveullbvseln4"
};


export default awsmobile;
