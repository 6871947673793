import { FETCH_PROFILES_REQUEST, FETCH_PROFILES_SUCCESS, FETCH_PROFILES_FAILURE } from "./types";

const initialState = {
    items: [],
    loading: false,
    error: null,
};

const profilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROFILES_REQUEST:
            return {
                ...state,
                loadding: true,
            };
        case FETCH_PROFILES_SUCCESS:
            return {
                ...state,
                items: action.payload,
                loadding: false,
            };
        case FETCH_PROFILES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadding: false,
            };
        default:
            return state;
    }
};

export default profilesReducer;
