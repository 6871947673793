import React from "react";
import { BrowserRouter } from "react-router-dom";
import { App } from "shared";
import { Provider } from "react-redux";
import store from "redux/store";

const Root = () => (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

export default Root;
