/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      name
      year
      month
      day
      lunar
      leapMonth
      timezone
      locale
      language
      sendmail
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        year
        month
        day
        lunar
        leapMonth
        timezone
        locale
        language
        sendmail
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      writer
      message
      createdAt
      updatedAt
      owner
      recommends {
        items {
          id
          messageID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        writer
        message
        createdAt
        updatedAt
        owner
        recommends {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRecommend = /* GraphQL */ `
  query GetRecommend($id: ID!) {
    getRecommend(id: $id) {
      id
      messageID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRecommends = /* GraphQL */ `
  query ListRecommends(
    $filter: ModelRecommendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecommends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
