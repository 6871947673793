import { FETCH_PROFILES_REQUEST, FETCH_PROFILES_SUCCESS, FETCH_PROFILES_FAILURE } from "./types";
import { API, graphqlOperation } from "aws-amplify";
import { listProfiles } from "graphql/queries";
import { onCreateProfile, onUpdateProfile, onDeleteProfile } from "graphql/subscriptions";

const moment = require("moment");

export const subscribeProfile = () => {
    return (dispatch) => {
        API.graphql(
            graphqlOperation(onCreateProfile, {
                // owner: username,
            })
        ).subscribe({
            next: ({ provider, pf }) => {
                console.log("onCreateProfile");
                console.log(pf);
                dispatch(fetchProfiles());
            },
        });
        API.graphql(graphqlOperation(onUpdateProfile)).subscribe({
            next: ({ provider, profile }) => {
                console.log("onUpdateProfile");
                dispatch(fetchProfiles());
            },
        });
        API.graphql(graphqlOperation(onDeleteProfile)).subscribe({
            next: ({ provider, profile }) => {
                console.log("onDeleteProfile");
                dispatch(fetchProfiles());
            },
        });
    };
};

export const fetchProfiles = () => {
    return (dispatch) => {
        const filter = {
            or: {
                and: {
                    month: { eq: moment().month() + 1 },
                    day: { eq: moment().get("date") },
                    lunar: { eq: false },
                },
                and: {
                    month: { eq: 6 },
                    day: { eq: 20 },
                    lunar: { eq: true },
                },
            },
        };
        // console.log(filter);

        dispatch(fetchProfilesRequest());
        API.graphql({
            query: listProfiles,
            // variables: { filter: filter },
            authMode: "API_KEY",
        })
            .then(
                ({
                    data: {
                        listProfiles: { items },
                    },
                }) => dispatch(fetchProfilesSuccess(items))
            )
            .catch((error) => dispatch(fetchProfilesFailure(error)));
    };
};

export const fetchProfilesRequest = () => {
    return {
        type: FETCH_PROFILES_REQUEST,
    };
};

export const fetchProfilesSuccess = (items) => {
    return {
        type: FETCH_PROFILES_SUCCESS,
        payload: items,
    };
};

export const fetchProfilesFailure = (error) => {
    return {
        type: FETCH_PROFILES_FAILURE,
        payload: error,
    };
};
