import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar, Nav, Form } from "react-bootstrap";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getProfile } from "graphql/queries";

const Menu = ({ username }) => {
    // translation
    const { t, i18n } = useTranslation();
    const [displayName, setDisplayName] = useState(username);

    // init function
    useEffect(() => {
        fetchUserName();
    }, [username]);

    async function fetchUserName() {
        console.log("Menu => fetchUserName");
        if (username) {
            API.graphql(graphqlOperation(getProfile, { id: username }))
                .then(({ data: { getProfile } }) => {
                    // console.log("result: ", apiData);
                    if (getProfile) {
                        setDisplayName(getProfile.name);
                    }
                })
                .catch((err) => {
                    console.log("Menu => fetchUserName", err);
                });
        }
    }

    return (
        <Navbar bg="light" expand="lg" className="px-5">
            <Navbar.Brand href="/">{t("title")}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link key="mypage" href="/mypage">
                        {t("menu.mypage")}
                    </Nav.Link>
                    <Nav.Link key="about" href="/about">
                        {t("menu.about")}
                    </Nav.Link>
                </Nav>
                {!displayName && <Nav.Link href="/mypage">{t("login.signin")}</Nav.Link>}
                {!!displayName && (
                    <Nav.Link href="/" onClick={() => Auth.signOut()}>
                        {t("login.signout")} ({displayName})
                    </Nav.Link>
                )}
                <Form.Control
                    as="select"
                    key="language"
                    size="sm"
                    value={i18n.language}
                    onChange={(e) => i18n.changeLanguage(e.target.value)}
                    style={{ width: "60px" }}
                >
                    {i18n.options.whitelist.map(
                        (v, index) =>
                            v !== "cimode" && (
                                <option key={v} value={v}>
                                    {v.toUpperCase()}
                                </option>
                            )
                    )}
                </Form.Control>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Menu;
