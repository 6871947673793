import React from "react";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";

const Footer = ({ username }) => {
    // translation
    const { t, i18n } = useTranslation();

    return (
        <footer className="pt-4 my-md-5 pt-md-5 border-top">
            <Nav.Link key="privacy" href="/privacy">
                {t("menu.privacy")}
            </Nav.Link>
            <Nav.Link key="terms" href="/terms">
                {t("menu.terms")}
            </Nav.Link>
        </footer>
    );
};

export default Footer;
